form#singleUploadForm {
  display: inline;
}

.autosaleModel {
  top: 20;
  width: fit-content !important;
}
.autosaleModel :where(.css-dev-only-do-not-override-14qglws).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0bb197 !important;
  border-color: #0bb197 !important;
}
.autosaleModel :where(.css-dev-only-do-not-override-14qglws).ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #0bb197 !important;
  border-color: #0bb197 !important;
}
.autosaleModel :where(.css-dev-only-do-not-override-14qglws).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #0bb197 !important;
  background-color: #0bb197 !important;
}
.autosaleModel .ant-radio-wrapper:hover :where(.css-dev-only-do-not-override-14qglws).ant-radio-wrapper,
.autosaleModel :where(.css-dev-only-do-not-override-14qglws).ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #0bb197;
}
.autosaleModel .ant-modal-footer {
  display: none;
}
.autosaleModel :where(.css-dev-only-do-not-override-1xg9z9n).ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #0bb197;
  border-color: #0bb197;
}
.autosaleModel .ant-modal-content {
  padding: 20px 20px !important;
}
.autosaleModel .autosaleModel_steps {
  width: 77%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 10px 0;
}
.autosaleModel p.autosale_model_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.autosaleModel .autosale_Body {
  display: block;
}
.autosaleModel .autosale_Body .body_page {
  display: block;
  max-height: 400px !important;
  overflow: hidden;
  width: 674px;
}
.autosaleModel .autosale_Body .body_page .page_inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.autosaleModel .autosale_Body .body_page .page_inner .shadow_div {
  border: 1px solid #d5d5d5 !important;
  padding: 15px;
  border-radius: 10px;
  margin: 15px 0;
}
.autosaleModel .autosale_Body .body_page .page_inner .radio_btn_div {
  display: flex;
  justify-content: center;
  padding: 27px 20px !important;
  width: 100%;
  outline: none;
}
.autosaleModel .autosale_Body .body_page .page_inner .radio_btn_div .radio_btn_item {
  font-size: 16px !important;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element {
  display: flex;
  margin: 0px 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element .name {
  display: block;
  padding: 7px 0;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 10px;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element .name :where(.css-dev-only-do-not-override-14qglws).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #0bb197;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element .name .ant-select-focused:where(.css-dev-only-do-not-override-14qglws).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #0bb197;
  outline: 0;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element .name .d-block {
  display: block !important;
  font-size: 16px;
  color: #787878;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element .name .autosale_input_eliment {
  padding: 8px 10px;
  border: 1px solid #dbd7d7;
  width: 300px;
  border-radius: 7px;
  font-size: 15px;
  color: #000000;
  outline: none;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element .name .autosale_input_eliment_Select {
  border: 1px solid #dbd7d7;
  width: 300px;
  border-radius: 7px;
  font-size: 15px;
  color: #000000;
  outline: none;
}
.autosaleModel .autosale_Body .body_page .page_inner .is_Form_Element .name .ant-select-show-search {
  padding: 0px 0px;
  border: 0px solid #dbd7d7;
  width: 300px;
  height: 40px;
  border-radius: 7px;
  font-size: 15px;
  color: #000000;
  outline: none;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div {
  display: block;
  width: 93%;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* Column width distribution */
  /* Additional styles for better appearance */
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table .tbody {
  height: 160px !important;
  display: block;
  overflow-x: hidden;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table th,
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table td {
  padding: 2px 6px;
  text-align: center;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table th:nth-child(1),
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table td:nth-child(1) {
  width: 5%;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table th:nth-child(2),
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table td:nth-child(2) {
  width: 43%;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table th:nth-child(3),
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table td:nth-child(3),
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table th:nth-child(4),
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table td:nth-child(4),
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table th:nth-child(5),
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table td:nth-child(5) {
  width: 17%;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table .table_item_element {
  width: 100%;
  padding: 2px;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table .table_item_element .ant-select-selector {
  text-align: left;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table .table_input_element {
  width: 100%;
  padding: 4px 2px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #dadada;
  border-radius: 7px;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table .tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.autosaleModel .autosale_Body .body_page .page_inner .custom_item_div .custom_item_table .tbody tr:hover {
  background-color: #f1f1f1;
}
.autosaleModel .autosale_Body .autosale_btn_div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.autosaleModel .autosale_Body .autosale_btn_div .Next_btn_div {
  display: flex;
}
.autosaleModel .autosale_Body .autosale_btn_div .Next_btn_div .btn-primary.disabled,
.autosaleModel .autosale_Body .autosale_btn_div .Next_btn_div .btn-primary:disabled {
  border-color: #0bb197 !important;
}
.autosaleModel .autosale_Body .autosale_btn_div .Next_btn_div .autosale_btn {
  width: 85px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
.autosaleModel .autosale_Body .autosale_btn_div .Next_btn_div .purchase_checkbox {
  width: 168px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-top: 20px;
  align-items: center;
  font-size: 16px;
}

.icon-loader-model .ant-modal-content {
  background-color: rgba(245, 235, 235, 0);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0), 0 3px 6px -4px rgba(0, 0, 0, 0), 0 9px 28px 8px rgba(0, 0, 0, 0);
}

.splitMenu-model :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-cell,
.splitMenu-model :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead > tr > th,
.splitMenu-model :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > td {
  position: relative;
  padding: 5px 5px !important;
  font-size: 12px;
  overflow-wrap: break-word;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > th > .ant-table-wrapper:only-child .ant-table,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > th > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin-block: 6px !important;
  margin-inline: -4px -5px !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 8px;
}

button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-dashed {
  padding: 0;
  width: 35px;
  height: 35px;
}
button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-dashed a.backto-rolemanagement {
  width: 100%;
  display: block;
  height: 100%;
  line-height: 2.4;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead > tr > td {
  background: #e0e9e8 !important;
}

.ag-selection-checkbox.ag-invisible {
  display: none !important;
}

.deleteSelectedRow {
  position: absolute;
  bottom: 52px;
  left: 10px;
}

button.ant-btn.css-1xg9z9n.ant-btn-default.company-popup-card {
  border: transparent;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.0196078431);
  color: rgba(0, 0, 0, 0.8784313725);
}

:where(.css-1xg9z9n).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #4096ff;
  border-color: rgba(134, 88, 88, 0);
  background: #ffffff;
}

.center-header-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.TransactionsTable-table .ag-theme-balham .ag-cell-value {
  word-break: normal;
}
.TransactionsTable-table .ag-theme-balham :where(.css-dev-only-do-not-override-1xg9z9n).ant-btn {
  font-size: 20px;
  line-height: 1.5714285714;
  height: 32px;
  padding: 0px 0 0 0px;
  border-radius: 6px;
}
.TransactionsTable-table .ag-theme-balham .transactions_table_ag .action_btn_Div {
  display: flex;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-select .ant-select-arrow {
  top: 49% !important;
}

.ag-theme-balham > .ag-ltr .ag-cell {
  border-right: 1px solid #d9dddf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 5px 4px 5px;
}

.ag-theme-alpine .ag-header-row {
  font-weight: 600 !important;
}

select.banking-dropdoun {
  text-transform: none;
  width: 100%;
  margin: 5px 0px 12px;
  padding: 5px 10px;
  border-color: blue;
}

.ant-modal.css-1xg9z9n.TallySyncTable-model {
  width: 704px !important;
}

.ant-modal.css-dev-only-do-not-override-1xg9z9n.TallySyncTable-model {
  width: 704px !important;
}

.TallySyncTable-model .ant-modal-content {
  padding: 0px !important;
}

.TallySyncTable-model button.ant-modal-close {
  display: none;
}

.hidden {
  display: none !important;
}

.TallySyncTable-outer {
  display: block;
}
.TallySyncTable-outer .TallySyncTable-inner {
  display: block;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-header {
  display: block;
  border-bottom: 1px solid black;
  padding: 10px 20px;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-header p.TallySyncTable-header-text {
  font-size: 19px;
  font-weight: 500;
  padding: 0;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body {
  display: block;
  padding: 10px 20px;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body .TallySyncTable-body li {
  padding: 5px 0;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li.TallySyncTable-body-text {
  font-size: 16px;
  padding: 3px 0;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li.TallySyncTable-body-text span.anticon.anticon-check-circle {
  font-size: 22px;
  margin-right: 20px;
  display: inline;
  margin-top: 4px;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li.TallySyncTable-body-text ul .TallySyncTable-body-text-sub {
  list-style-type: circle !important;
  margin-left: 50px;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li.TallySyncTable-body-text ul .TallySyncTable-body-text-sub span {
  font-weight: 700;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li table {
  border-collapse: collapse;
  margin: 10px 0 !important;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li table tr th:first-child {
  width: 25%;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li table tr th {
  border: 1px solid rgb(199, 199, 199);
  width: 19%;
  padding: 5px 9px;
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-body li table tr td {
  border: 1px solid rgb(199, 199, 199);
  padding: 5px 0px;
  text-align: center;
  font-size: 20px;
  font-weight: 600 !important;
}
.TallySyncTable-outer .TallySyncTable-inner .TallySyncTable-footer {
  display: flex;
  justify-content: end;
  padding: 5px 15px 10px;
}

.ant-modal.css-1xg9z9n.errorMenu-model {
  width: 600px !important;
}

.ant-modal.css-1xg9z9n.errorMenu-model :where(.css-1xg9z9n).ant-modal .ant-modal-close {
  top: 14px;
}

.errorMenu-model .ant-modal-content {
  padding: 0px !important;
}

:where(.css-1xg9z9n).ant-modal .ant-modal-content {
  border-radius: 5px;
}

.errorMenu-model .errorMenu-outer {
  display: block;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner {
  display: block;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-header {
  display: block;
  border-bottom: 1px solid rgb(156, 156, 156);
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-header p.errorMenu-model-header {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-body {
  padding: 10px 15px 12px;
  display: block;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-body .mismatched-line {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
  color: #e3727f !important;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-body .mismatched-line p.errorMenu-body-mismatched-line {
  margin-right: 30px;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-body .note-line {
  display: block;
  font-size: 16px;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-body .note-line p.errorMenu-body-note-line {
  margin: 10px 0;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-body .note-line p.errorMenu-body-note-line span {
  font-weight: 600;
}
.errorMenu-model .errorMenu-outer .errorMenu-inner .errorMenu-body .note-line p.errorMenu-body-note-line.errorMenu-note {
  font-size: 14px;
}

.ant-modal.css-dev-only-do-not-override-1xg9z9n.splitMenu-model .ant-modal-content {
  padding: 0 !important;
}

.splitMenu-model.ant-modal.css-dev-only-do-not-override-1xg9z9n.splitMenu-model {
  width: 600px !important;
}

.ant-modal.css-1xg9z9n.splitMenu-model {
  width: 600px !important;
}

.splitMenu-model td.ant-table-cell {
  line-height: 1.5 !important;
}

.splitMenu-model .ant-modal-content {
  padding: 0 !important;
}

.splitMenu-outer .splitMenu-inner .splitMenu-header {
  padding: 12px 15px 0;
}
.splitMenu-outer .splitMenu-inner .splitMenu-header p {
  font-size: 14px;
  letter-spacing: -1px;
  padding: 0px 33px 0px 0px;
}
.splitMenu-outer .splitMenu-inner .splitMenu-header:after {
  background-color: #d9d9d9;
  content: "";
  display: block;
  height: 1px;
  margin-left: -14px;
  margin-top: 8px;
  width: 104%;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body .body-split-Topbar {
  padding: 15px;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body .body-split-Topbar .Topbar-Amount {
  font-size: 16px;
  font-weight: 500;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body .body-split-Topbar .Topbar-Amount .Topbar-Remaining {
  color: #3672f7;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body .body-split-Topbar .Topbar-input {
  font-size: 16px;
  padding: 7px 0 0 0;
  display: flex;
  align-items: end;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body .body-split-Topbar .Topbar-input .Topbar-input-SelectLedger {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body .body-split-Topbar .Topbar-input input.ant-input.css-dev-only-do-not-override-1xg9z9n.ant-input-outlined {
  margin: 5px 0 0 0;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-1xg9z9n).ant-table-wrapper,
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper {
  clear: both;
  max-width: 100%;
  padding: 0 15px 15px;
}
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-14qglws).ant-table-wrapper .ant-table-cell,
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-14qglws).ant-table-wrapper .ant-table-thead > tr > th,
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-14qglws).ant-table-wrapper .ant-table-tbody > tr > th,
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-14qglws).ant-table-wrapper .ant-table-tbody > tr > td,
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-14qglws).ant-table-wrapper tfoot > tr > th,
.splitMenu-outer .splitMenu-inner .splitMenu-body :where(.css-14qglws).ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 1px 7px;
  overflow-wrap: break-word;
}
.splitMenu-outer .splitMenu-inner .splitMenu-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 15px;
}
.splitMenu-outer .splitMenu-inner .splitMenu-footer span button.ant-btn.css-dev-only-do-not-override-14qglws.ant-btn-primary.ant-btn-solid.split-add-btn {
  margin-left: 10px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-space-gap-col-small {
  column-gap: 0px !important;
}

.ant-space.css-1xg9z9n.ant-space-horizontal.ant-space-align-center.ant-space-gap-row-small.ant-space-gap-col-small {
  padding: 0 5px;
}

.TransactionsTable {
  display: block;
}
.TransactionsTable .ag-header-cell.header-center .ag-header-cell-label {
  justify-content: center;
}
.TransactionsTable .TransactionsTable-heading {
  background-color: #eff2f7;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px;
}
.TransactionsTable .TransactionsTable-heading .table-title {
  display: flex;
  justify-content: space-between;
}
.TransactionsTable .TransactionsTable-heading .table-title button {
  margin: auto 10px auto 0px;
  width: 25px;
  height: 25px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.TransactionsTable .TransactionsTable-heading .table-title button span.anticon.anticon-arrow-left {
  display: block;
}
.TransactionsTable .TransactionsTable-heading .table-title p {
  margin: auto 0;
  font-size: 17px;
}
.TransactionsTable .TransactionsTable-heading .table-title .TransactionsNumber {
  margin: auto 0 auto 5px;
  background-color: #0bb197;
  border-radius: 100%;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 6px;
}
.TransactionsTable .TransactionsTable-heading .table-title small {
  margin: auto 0 auto 5px;
  font-size: 18px;
}
.TransactionsTable .TransactionsTable-heading .table-company-title {
  display: flex;
  margin: auto 0;
  align-items: end;
}
.TransactionsTable .TransactionsTable-heading .table-company-title span {
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 2px;
}
.TransactionsTable .TransactionsTable-heading .table-company-title p {
  font-size: 20px;
  color: #0bb197;
}
.TransactionsTable .TransactionsTable-top-heading {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 12px;
  height: 120px;
}
.TransactionsTable .TransactionsTable-top-heading .operations {
  width: 25%;
}
.TransactionsTable .TransactionsTable-top-heading .operations .operations-inner {
  align-items: center;
}
.TransactionsTable .TransactionsTable-top-heading .operations .operations-inner p {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
}
.TransactionsTable .TransactionsTable-top-heading .operations .operations-inner small {
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
}
.TransactionsTable .TransactionsTable-top-heading .operations span {
  font-size: 14px;
  display: block;
  margin: 8px 0;
}
.TransactionsTable .TransactionsTable-top-heading .operations input {
  font-size: 14px;
}
.TransactionsTable .TransactionsTable-top-heading .filters {
  display: block;
  width: 50%;
}
.TransactionsTable .TransactionsTable-top-heading .filters p {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-checkbox {
  display: flex;
  margin: 12px 0;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-checkbox .checkbox {
  margin-right: 1%;
  display: flex;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-checkbox .checkbox input {
  width: 15px !important;
  height: 17px !important;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-checkbox .checkbox label {
  margin: 0 0 0 7px;
  font-size: 12px;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-date {
  display: flex;
  align-items: center;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-date .date-filter {
  margin-right: 57px;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-date .date-filter :where(.css-dev-only-do-not-override-14qglws).ant-picker-outlined:hover {
  border-color: #0bb197;
  background-color: #ffffff;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-date .creditdebit {
  display: block;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-date .creditdebit .Rs-text {
  margin-right: 10px;
  margin-left: 30px;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-date .creditdebit p.credit-amount {
  color: #00a900;
}
.TransactionsTable .TransactionsTable-top-heading .filters .filters-date .creditdebit p.debit-amount {
  color: red;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit {
  font-size: 14px;
  display: flex;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit .date .ant-picker.ant-picker-range.ant-picker-outlined.css-1xg9z9n.RangePicker {
  padding: 0px 11px 0px !important;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit .date button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-default.remove-filter-btn {
  height: 26px !important;
  width: 26px !important;
  margin-left: 10px !important;
  padding: 1px 3px !important;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit .date button.ant-btn.css-1xg9z9n.ant-btn-default.remove-filter-btn {
  margin-left: 5px;
  width: 26px;
  height: 24px;
  margin-top: 0px;
  padding: 0px 0px;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit .creditdebit {
  display: flex;
  align-items: center;
  margin-left: 4%;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit .creditdebit .amount {
  margin: 0 15px 0 5px;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit .creditdebit .credit-Rs {
  color: #49ed49;
}
.TransactionsTable .TransactionsTable-top-heading .filters .date-creditdebit .creditdebit .debit-Rs {
  color: #ff0000;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 25%;
  position: relative;
  margin: -5px 0;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  margin: 0 0 11px 0;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line .all-btn :where(.css-dev-only-do-not-override-14qglws).ant-btn-primary.ant-btn-solid {
  color: #fff;
  background: #0bb197;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line .all-btn button {
  width: fit-content !important;
  height: 32px;
  margin-left: 15px;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line .all-btn :where(.css-dev-only-do-not-override-14qglws).ant-btn-default.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line .all-btn :where(.css-dev-only-do-not-override-14qglws).ant-btn-default.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #0bb197;
  border-color: #0bb197;
  background: #ffffff;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line .all-btn :where(.css-dev-only-do-not-override-1xg9z9n).ant-btn > .anticon + span,
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line .all-btn :where(.css-dev-only-do-not-override-1xg9z9n).ant-btn > span + .anticon {
  margin-inline-start: 6px;
  font-size: 14px;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line .save-btn-menu button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-default {
  margin-top: 8px;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line button.bulkupload-topbar-btn.bulkupload-btn.btn-outline-danger.bg-btn {
  height: 30px;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line button.bulkupload-topbar-btn.bulkupload-btn.btn-outline-danger.bg-btn span.anticon.anticon-youtube.topobar-icon.text-danger {
  color: #dc3545 !important;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .controal-btn-line button.bulkupload-topbar-btn.bulkupload-btn.btn-outline-danger.bg-btn span.anticon.anticon-youtube.topobar-icon.text-danger:hover svg {
  color: #fff;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .transactionstable-sidemeny-btn {
  width: 25px;
  height: 30px;
  position: absolute;
  right: -15px;
}
.TransactionsTable .TransactionsTable-top-heading .controal-btn .transactionstable-sidemeny-btn span.anticon.anticon-double-left {
  margin-left: -6px;
}
.TransactionsTable span.anticon.anticon-menu-unfold.split-icon {
  font-size: 18px;
}
.TransactionsTable span.anticon.anticon-delete.delete-icon {
  font-size: 18px;
}
.TransactionsTable .TransactionsTable-delete-btn {
  position: absolute;
  bottom: 8px;
  left: 12px;
  padding: 0 15px;
}

.ag-main-table {
  height: 100% !important;
}
.ag-main-table :where(.css-14qglws).ant-btn {
  font-size: 18px;
  line-height: 1.5714285714;
  padding: 0px 5px;
  border-radius: 6px;
}
.ag-main-table .hide_btn_class {
  font-size: 18px;
  display: none;
}
.ag-main-table .action_dropdown_icon {
  width: 31px;
  height: 30px;
  border-radius: 15px !important;
}
.ag-main-table .action_dropdown_icon:hover {
  background-color: rgba(24, 144, 255, 0.2);
}

@media (min-width: 1100px) {
  .ag-main-table .action_dropdown_icon {
    display: none;
  }
  .ag-main-table .hide_btn_class {
    display: block;
  }
}
.my-plan {
  display: block;
  padding: 0 15px;
}
.my-plan .my-plan-ul {
  display: block;
}
.my-plan .my-plan-ul .my-plan-li {
  border-bottom: 1px solid #e1e1e1;
  margin: 0 -15px;
  padding: 8px 15px;
}
.my-plan .my-plan-ul .my-plan-li .show-btn {
  color: #0bb197;
}
.my-plan .my-plan-ul .my-plan-li .show-btn svg {
  font-size: 12px;
  margin-left: 4px;
}
.my-plan .my-plan-ul .my-plan-li .show-component {
  display: none;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul {
  display: flex;
  margin: 0 100px;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li {
  border: 1px solid #0bb197;
  border-radius: 10px;
  width: 33.33%;
  margin: 10px 20px;
  padding: 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one {
  display: block;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one sup {
  top: -1.5em;
  font-size: 20px;
  color: #0bb197;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .price {
  display: inline;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .price p {
  font-size: 40px;
  display: inline;
  color: #0bb197;
  margin-left: -13px;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .price sub {
  font-size: 13px;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .count p {
  margin: 10px 0;
  font-size: 14px;
  display: inline;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .count button#btn {
  border-radius: 30px;
  width: 20px;
  height: 20px;
  line-height: 0;
  padding-left: 5px;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .count small {
  margin: 0 5px;
  font-size: 16px;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .firstPlanval {
  color: #0bb197;
  font-size: 14px;
  margin: 10px 0;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .showmore-btn {
  color: #0bb197;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .showmore-ul {
  display: none;
  list-style-type: square !important;
  color: black;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .showmore-ul li {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  display: block;
  font-size: 14px;
  margin: 6px 0 6px 35px;
}
.my-plan .my-plan-ul .my-plan-li .show-component .myplan-sub-ul .myplan-sub-li .plan-one .showmore-ul.show {
  display: block;
}
.my-plan .my-plan-ul .my-plan-li .show-component.show {
  display: block;
  padding: 5px 0 12px;
}

.mapping-outer {
  display: block;
}
.mapping-outer .mapping-inner {
  display: block;
}
.mapping-outer .mapping-inner .mapping-heading-outer {
  border-bottom: 2px solid #eff2f7;
  height: 10%;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  height: 70px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .beck-btn {
  display: flex;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .beck-btn Button {
  width: 25px;
  height: 25px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 5px 0 9px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .beck-btn Button span.anticon.anticon-arrow-left {
  margin-left: -5px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .beck-btn p svg {
  width: 20px;
  height: 20px;
  margin: 2px 0 0 6px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .Steps-div {
  width: 550px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .invoice-btn {
  display: flex;
  align-items: center;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .invoice-btn .invoice-common {
  margin: 0 0 0 10px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .invoice-btn .Configration-btn {
  margin: 0 20px;
  width: fit-content;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .invoice-btn .Configration-btn span {
  font-size: 14px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .invoice-btn .mapping-ytb-btn {
  border-radius: 5px;
}
.mapping-outer .mapping-inner .mapping-heading-outer .mapping-heading .invoice-btn .mapping-ytb-btn:hover svg {
  color: white;
}
.mapping-outer .mapping-inner .mapping-body-outer {
  margin: 15px 0px;
  height: calc(100vh - 140px);
  overflow-x: hidden;
}
.mapping-outer .mapping-inner .mapping-body-outer .export-toggle-container {
  display: flex;
  align-items: center;
  margin: 0 15px;
}
.mapping-outer .mapping-inner .mapping-body-outer .export-toggle-container button.ant-switch.export-toggal-switch.css-dev-only-do-not-override-14qglws {
  margin: 0 10px 0px;
}
.mapping-outer .mapping-inner .mapping-body-outer .excel-confirm {
  display: flex;
  margin: 0 15px;
  padding: 5px 0;
}
.mapping-outer .mapping-inner .mapping-body-outer .excel-confirm .excel-confirm-btn {
  margin-right: 45px;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body {
  display: flex;
  margin: 15px 0px 0;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-w-100 {
  width: 100% !important;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun {
  width: 50%;
  box-shadow: none;
  padding: 0 15px;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .box-heding {
  display: flex;
  align-items: center;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .box-heding p {
  font-size: 22px;
  font-weight: 600;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .box-heding span {
  color: white;
  background-color: #1c00f1;
  margin-left: 10px;
  padding: 0px 6px;
  height: 25px;
  line-height: 25px;
  border-radius: 30px;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table {
  border: 2px solid rgb(159, 158, 158);
  border-radius: 5px;
  margin: 10px 0 0 0;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table {
  display: flex;
  flex-direction: column;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr {
  display: flex;
  width: 100%;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr th:first-child,
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr td:first-child {
  width: 26%;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr th {
  background-color: #eff2f7;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr th,
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr td {
  width: 44%;
  font-size: 12px;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr td select#mappingColumn {
  width: 95%;
  padding: 8px 6px;
  border-radius: 5px;
  position: relative;
  border-color: #eff2f7;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr td select#mappingColumn .remove-heading-btn {
  position: absolute;
  left: 20px;
  background-color: #dc3545 !important;
  color: rgb(170, 35, 35) !important;
}
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr th:last-child,
.mapping-outer .mapping-inner .mapping-body-outer .mapping-body .maped-box-commun .table table tr td:last-child {
  width: 30%;
}
.mapping-outer .mapping-inner .mapping-footer-outer {
  display: block;
  border-top: 2px solid #eff2f7;
}
.mapping-outer .mapping-inner .mapping-footer-outer .mapping-footer {
  display: flex;
  justify-content: end;
  margin: 0 15px;
}
.mapping-outer .mapping-inner .mapping-footer-outer .mapping-footer .mapping-btn {
  margin-left: 15px;
}

.user-heading {
  background-color: #f8f8f8;
  height: 100%;
}
.user-heading .user-heading-ul {
  display: flex;
  margin: 0 0px;
}
.user-heading .user-heading-ul .user-heading-li {
  box-sizing: border-box;
  padding: 7px 15px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
}
.user-heading .user-heading-ul .user-heading-li .user-heading-li-a {
  font-size: 12px;
  letter-spacing: 1px;
  color: #8e8e8f;
}
.user-heading .user-heading-ul .active a {
  color: #0bb197 !important;
  font-size: 12px;
  letter-spacing: 1px;
}

.Users_Div {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
  background-color: white;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
}
.Users_Div .Users_Table th.ant-table-cell {
  padding: 10px;
}
.Users_Div .Users_Table td.ant-table-cell {
  padding: 5px;
}

.Role_Management_Div .Role_Management_heading {
  border: 1px solid #d3d1d1;
  margin: 15px;
  padding: 15px;
  border-radius: 10px;
  display: block;
  background-color: white;
}
.Role_Management_Div .Role_Management_Table {
  border: 1px solid #d3d1d1;
  margin: 15px;
  border-radius: 10px;
  display: block;
  background-color: white;
}

/* MyProfileForm component start*/
.my_profile {
  width: 60%;
  margin: 15px auto 0;
}
.my_profile .profile_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my_profile .profile_heading .profile_heading_p {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.3px;
}
.my_profile form.my_profile_form {
  display: block;
}
.my_profile form.my_profile_form .profile_content {
  display: block;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  margin: 15px 0;
  border: 1px solid #e3e3e3;
}
.my_profile form.my_profile_form .profile_content .profile_body {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}
.my_profile form.my_profile_form .profile_content .profile_body .name {
  width: 50%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}
.my_profile form.my_profile_form .profile_content .profile_body .name .email_mo_div {
  display: flex;
  align-items: self-end;
  justify-content: space-between;
}
.my_profile form.my_profile_form .profile_content .profile_body .name .email_mo_div .email_mo_update_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #0bb197;
  cursor: pointer;
}
.my_profile form.my_profile_form .profile_content .profile_body .name .email_mo_div .email_mo_update_btn .email_mo_update_btn_icon {
  width: 14px;
  margin-right: 2px;
}
.my_profile form.my_profile_form .profile_content .profile_body .name label.my_profile_label {
  font-size: 16px;
}
.my_profile form.my_profile_form .profile_content .profile_body .name .input_padding {
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}
.my_profile form.my_profile_form .profile_content .profile_body .name .input_Profile_Logo_padding {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #c0bebe;
  margin-top: 10px;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}
.my_profile form.my_profile_form .profile_content .my_profile_div {
  display: flex;
  justify-content: center;
}
.my_profile form.my_profile_form .profile_content .my_profile_div .my_profile_btn {
  color: #fff;
  background-color: #0bb197;
  border-color: #6c757d;
}

/* MyProfileForm component end*/
/* header notification start  */
/* header notification end  */
/*  AutoExpensesForm start  */
.custom-table .ant-table-cell {
  padding: 5px !important; /* Set padding for all cells, including header */
}

.custom-table .ant-table-thead > tr > th {
  padding: 5px !important; /* Set padding for header cells */
}

.autoExpensesForm-modal input.ant-input.css-dev-only-do-not-override-14qglws.ant-input-outlined.expense_select_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  margin: 0 auto;
  font-size: 25px;
  background-color: #0bb197;
  outline: none;
}

.auto_expence_party_name_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.auto_expence_party_name_div label.auto_expence_party_name {
  font-size: 22px;
  margin-right: 20px;
}
.auto_expence_party_name_div .ant-select.ant-select-outlined.auto_expence_party_name_input_eliment_Select.css-dev-only-do-not-override-14qglws.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  width: 400px;
}
.auto_expence_party_name_div :where(.css-14qglws).ant-select-single {
  font-size: 14px;
  height: 32px;
  width: 400px;
}

/*  AutoExpensesForm end  */
/*  PaymentPage start  */
.checkout_div {
  display: block;
  background-color: #f7f7f7;
  height: 100vh;
}
.checkout_div .checkout_div_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
}
.checkout_div .checkout_div_inner .plan_div_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.checkout_div .checkout_div_inner .plan_div_heading .home_page_btn {
  outline: none !important;
  box-shadow: none;
  border: 1px solid gray;
  background-color: #022b50;
  padding: 15px 50px;
  border-radius: 16px;
  margin-top: 25px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.checkout_div .checkout_div_inner .plan_div_heading p.heading_title {
  color: #022b50;
  font-size: 42px;
  font-weight: 700;
}
.checkout_div .checkout_div_inner .plan_div_heading span.heading_details {
  font-size: 18px;
  color: #838383;
  text-align: center;
  width: 60%;
}
.checkout_div .checkout_div_inner .payment_container_div {
  display: block;
  margin: 25px 0;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div {
  display: flex;
  justify-content: center;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .checkout_card {
  background-color: #ffffff;
  padding: 35px 50px;
  border-radius: 12px;
  width: 30%;
  max-width: 700px;
  margin: 0px 15px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .checkout_card p.card_title {
  font-size: 26px;
  font-weight: 600;
  padding-bottom: 5px;
  color: #022b50;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div {
  display: block;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div p.card_title {
  border-bottom: 1px solid rgba(165, 165, 165, 0.18);
  margin-bottom: 15px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div_flax {
  display: flex;
  margin: 10px -10px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div_flax .input_div {
  display: block;
  width: 100%;
  margin: 5px 10px 15px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div_flax .input_div label {
  display: inline-block;
  margin-bottom: 5px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div_flax .input_div input {
  padding: 8px 10px;
  border-radius: 7px;
  background: #faf9fc;
  border: 2px solid #ddd;
  /* margin-top: 5px; */
  font-size: 15px;
  width: 100%;
  outline: none;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div_flax .input_div input::placeholder {
  color: #bababa;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div {
  display: block;
  width: 100%;
  padding: 5px 0px;
  margin: 0px 0;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div label {
  display: inline-block;
  margin-bottom: 5px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div select {
  padding: 8px 10px;
  border-radius: 5px;
  background: #faf9fc;
  border: 2px solid #ddd;
  /* margin-top: 5px; */
  font-size: 14px;
  width: 100%;
  outline: none;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div select::placeholder {
  color: #bababa;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div input {
  padding: 8px 10px;
  border-radius: 7px;
  background: #faf9fc;
  border: 2px solid #ddd;
  margin-top: 0px;
  font-size: 14px;
  width: 100%;
  outline: none;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .billing_details_div .input_div input::placeholder {
  color: #bababa;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div {
  display: block;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div {
  background: #faf9fc;
  border: 2px solid #ddd;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 10px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div .item_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div .item_div p.dark_plan_text {
  font-weight: 700;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div .item_dark_div {
  border-bottom: 1px solid rgba(165, 165, 165, 0.18);
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div .item_dark_div p {
  font-weight: 700;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div .item_btn_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0px 0;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div .item_btn_div input {
  padding: 8px 10px;
  font-size: 14px;
  width: 70%;
  border-radius: 5px;
  border: 2px solid #ddd;
  outline: none;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div .product_div .item_btn_div button {
  padding: 8px 15px;
  font-size: 14px;
  background-color: #022b50;
  color: white;
  border: 2px solid #fff;
  border-radius: 5px;
  margin-left: 10px;
  width: 30%;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div span {
  display: block;
  padding: 15px 18px;
  line-height: 16px;
  font-size: 12px;
}
.checkout_div .checkout_div_inner .payment_container_div .body_div .your_order_div button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  border-radius: 7px;
  font-size: 14px;
  background-color: #022b50;
  color: white;
  font-weight: 700;
  border: none;
}
.checkout_div .checkout_div_inner .footer_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout_div .checkout_div_inner .footer_div p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  padding: 0;
}

.plan_div {
  display: block;
}
.plan_div .plan_div_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.plan_div .plan_div_inner .plan_div_body {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 25px 0;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner {
  display: flex;
  justify-content: center;
  width: 100%;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .body_left_card {
  width: 25%;
  max-width: 500px;
  background-color: white;
  color: #022b50;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .body_right_card {
  width: 40%;
  max-width: 800px;
  background-color: #022b50;
  color: #ffffff;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card {
  display: block;
  padding: 25px 25px;
  margin: 0 15px;
  border-radius: 15px;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card p.card_title {
  font-size: 26px;
  font-weight: 600;
  font-family: Poppins;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_title_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_title_div span.card_title_RECOMMENDED {
  background-color: rgba(255, 255, 255, 0.1490196078);
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 20px;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_feature ul {
  height: 185px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_feature ul li {
  list-style-type: inherit;
  margin: 0 0 0 30px;
  padding: 10px 0;
  font-size: 14px;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_btn_div {
  display: flex;
  justify-content: center;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_btn_div .card_btn {
  font-size: 14px;
  padding: 10px 50px;
  border-radius: 26px;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_btn_div button.left_card_btn {
  background-color: #022b50;
  color: #ffffff;
}
.plan_div .plan_div_inner .plan_div_body .plan_div_body_inner .card .card_btn_div button.right_card_btn {
  background-color: #ffffff;
  color: #022b50;
}

.bg-overlay {
  background-image: url("../../src/assets/images/auth-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.auth-bg {
  background-color: rgba(0, 0, 0, 0.6549019608);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.auth-bg .auth-page {
  background-color: #fff;
  font-size: 14px;
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
}
.auth-bg .auth-page .welcome_text {
  font-size: 18px;
}
.auth-bg .auth-page .text-muted {
  color: #6c757d !important;
}
.auth-bg .auth-page .titel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-bg .auth-page .titel .titel_text {
  font-size: 36px;
  font-weight: 700;
  margin-left: 5px;
  background: linear-gradient(173deg, #44f082, #86d6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.auth-bg .auth-page .text_cursor_pointer {
  cursor: pointer;
}
.auth-bg .auth-page div#container {
  padding: 2px 40px;
}
.auth-bg .auth-page .GoogleLogin_div {
  width: 100%;
  max-width: 400px;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-self: center !important;
  margin: 15px 0;
}
.auth-bg .auth-page .GoogleLogin_div .GoogleLogin button {
  max-width: 300px;
  width: 100%;
}
.auth-bg .auth-page .separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 0;
  color: #666;
  font-size: 14px;
}
.auth-bg .auth-page .separator span {
  white-space: nowrap;
  margin: 0 15px;
  font-size: 18px;
}
.auth-bg .auth-page .separator::before,
.auth-bg .auth-page .separator::after {
  content: "";
  flex: 1;
  border-top: 1px solid #ccc;
}
.auth-bg .auth-page form {
  font-size: 14px;
  min-width: 350px;
}
.auth-bg .auth-page form input,
.auth-bg .auth-page form button {
  font-size: 14px !important;
}
.auth-bg .auth-page form label {
  font-weight: 600;
}
.auth-bg .auth-page form .login_page_input_div {
  margin: 15px 0;
}
.auth-bg .auth-page form .forgot_div {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0px;
}
.auth-bg .auth-page form .log_in_btn {
  width: 100%;
  margin: 15px 0;
}

/*  PaymentPage end  */
@media (max-width: 1024px) {
  .chatbutton .chatbot {
    bottom: 55px;
    left: 45px;
  }
  .chatbutton .chatbot .chetbot-img {
    width: 46px;
  }
  .navbar-header {
    height: 46px;
  }
  .navbar-header .header-search-div .header_search_input {
    width: 125%;
    padding: 3px 10px;
    font-size: 12px;
  }
  .navbar-header .header-search-div .header_search_input .ant-input-prefix {
    margin-inline-end: 10px;
    font-size: 14px;
    color: #b5b5b5;
  }
  .navbar-header .header-icon-pd {
    padding: 0 7px 0px 7px;
  }
  .navbar-header .sidebar-togal-div {
    margin-left: 7px;
  }
  .navbar-header .navbar-brand-box {
    width: 160px;
  }
  .navbar-header .navbar-brand-box .logo {
    line-height: 45px;
  }
  .navbar-header .navbar-brand-box .logo .navbar-brand-box-small-img {
    width: 36px;
    height: 30px;
  }
  .navbar-header .navbar-brand-box-small {
    width: 90px;
  }
  .navbar-header .navbar-brand-box-small .logo {
    line-height: 45px;
  }
  .navbar-header .navbar-brand-box-small .logo .navbar-brand-box-small-img {
    width: 36px;
    height: 30px;
  }
  .navbar-header .sidebar-togal-btn svg {
    font-size: 18px;
  }
  .navbar-header .pathnamecapital {
    margin: 0 7px;
    font-size: 14px;
  }
  .navbar-header .pathnamecapital .pathnamecapital span.main_table_data-length {
    margin: 0 0 0 7px;
    padding: 0px 6px;
    font-size: 12px;
  }
  .navbar-header .header-right .icon-header-blue > svg {
    font-size: 20px;
  }
  .navbar-header .header-right .anticon.anticon-bell.icon-header-notification {
    font-size: 18px;
  }
  .navbar-header .header-right .mycompany-name-text {
    font-size: 14px;
    margin: 0 5px;
  }
  .navbar-header .header-right p.mycompany-icon-btn {
    font-size: 16px;
  }
  .navbar-header .header-right .header-admin-img {
    width: 28px;
    height: 28px;
  }
  .navbar-header .header-right .header-profile span {
    font-size: 12px;
  }
  .vertical-menu-small {
    top: 46px;
    width: 90px;
  }
  .vertical-menu-small #side-menu-small-itemp-li {
    padding: 0px 0px !important;
    margin: 6px 0px;
  }
  .vertical-menu-small #side-menu-small-itemp-li .sub-menu-small {
    left: 106%;
    width: 100px;
  }
  .vertical-menu-small #side-menu-small-itemp-li .sub-menu-small .sub-item-hover-small {
    padding: 5px 14px;
    font-size: 12px !important;
  }
  .vertical-menu-small #side-menu-small-itemp-li .single-icon-small {
    padding: 8px 3px !important;
    margin: 5px 5px;
  }
  .vertical-menu-small #side-menu-small-itemp-li .sidebar_icon {
    font-size: 16px;
    margin-bottom: 3px;
  }
  .vertical-menu-small #side-menu-small-itemp-li .single-icon-small > small {
    display: block;
    font-size: 11px !important;
  }
  .vertical-menu-big {
    top: 46px;
    width: 160px;
  }
  .vertical-menu-big #sidebar-menu ul li a {
    margin: 5px;
    padding: 6px 7px;
    font-size: 12px;
  }
  .vertical-menu-big #sidebar-menu ul li a div > span > svg {
    font-size: 15px;
  }
  .vertical-menu-big #sidebar-menu ul li .itemp-a .sidebar_icon_title_span {
    margin-left: 8px;
  }
  .vertical-menu-big .navbar-brand-box-big-img {
    width: 30px;
    height: 28px;
  }
  .vertical-menu-big .logo_text {
    font-size: 20px;
  }
  .main-content {
    padding: 46px 0px 0px 161px;
  }
  .main-content-small {
    padding: 46px 0 0 90px !important;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul {
    margin-top: 8px !important;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li:first-child,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li:first-child {
    margin-left: 5px;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li .bnk-hd-li-link,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li .bnk-hd-li-link {
    padding: 5px 15px;
    font-size: 11px;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .active .bnk-hd-li-link,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .active .bnk-hd-li-link {
    padding: 5px 15px;
    font-size: 11px;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn {
    margin: 7px 0 7px;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .Download-Sample-btn,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .Download-Sample-btn {
    height: 20px !important;
    padding: 0 7px;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn :where(.css-dev-only-do-not-override-14qglws).ant-btn > span,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn :where(.css-dev-only-do-not-override-14qglws).ant-btn > span {
    font-size: 12px;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .bulkupload-btn,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .bulkupload-btn {
    font-size: 11px;
    padding: 0px 8px;
    height: 20px;
  }
  .main-content-small .bulkupload-topbar .bulkupload-topbar-inner::after,
  .main-content .bulkupload-topbar .bulkupload-topbar-inner::after {
    margin: 0px 0px 0 0px;
  }
  .main-content-small .page-content,
  .main-content .page-content {
    padding: 0 0 38px;
  }
  .auth-bg .auth-page {
    font-size: 12px;
    display: block;
    width: 300px;
  }
  .auth-bg .auth-page .welcome_text {
    font-size: 16px;
  }
  .auth-bg .auth-page .titel img {
    width: 36px;
  }
  .auth-bg .auth-page .titel .titel_text {
    font-size: 26px;
  }
  .auth-bg .auth-page div#container {
    padding: 2px 40px;
  }
  .auth-bg .auth-page .GoogleLogin_div {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .auth-bg .auth-page .GoogleLogin_div iframe#gsi_269658_438372,
  .auth-bg .auth-page .GoogleLogin_div iframe#gsi_656432_986259 {
    width: 176px !important;
  }
  .auth-bg .auth-page .GoogleLogin_div .GoogleLogin button {
    max-width: 300px;
    width: 100%;
  }
  .auth-bg .auth-page .separator {
    margin: 5px 0;
    font-size: 14px;
  }
  .auth-bg .auth-page .login_page_alert_div {
    padding: 10px;
    font-size: 12px;
  }
  .auth-bg .auth-page form {
    min-width: 250px;
  }
  .auth-bg .auth-page form .form-control {
    width: 250px;
    padding: 3px 10px;
  }
  .auth-bg .auth-page form label {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .auth-bg .auth-page form .login_page_input_div {
    margin: 5px 0;
  }
  .auth-bg .auth-page form .forgot_div {
    margin: 10px 0 0px;
    font-size: 12px;
  }
  .auth-bg .auth-page form .log_in_btn {
    margin: 10px 0;
    padding: 4px 10px;
    font-size: 14px !important;
  }
  .companylist-model {
    width: 33% !important;
  }
  .companylist-model .company-dropdown-outer .dropdown-body-header {
    padding: 7px 7px 7px;
  }
  .companylist-model .company-dropdown-outer .dropdown-body-header .company_heading_andClose {
    font-size: 14px;
  }
  .companylist-model .company-dropdown-outer .dropdown-body-header .company_heading_andClose span.close_icon_btn {
    margin-right: 5px;
  }
  .companylist-model .company-dropdown-outer .dropdown-body-header .company_heading_andClose .company_refresh_btn {
    margin: 0 0 0 2px;
    width: 15px;
    height: 15px;
    font-size: 9px;
  }
  .companylist-model .company-dropdown-outer .dropdown-body-header .search_company_input {
    width: 40%;
    padding: 1px 6px;
    font-size: 12px;
  }
  .companylist-model .company-dropdown-outer .dropdown-body-header .company_manage_btn {
    font-size: 12px;
    padding: 0px 5px;
    border-radius: 4px;
    height: 22px;
  }
  .companylist-model .company-dropdown-outer .ant-list-item {
    font-size: 12px;
    margin: 10px;
    padding: 7px 10px;
  }
  .companylist-model .company-dropdown-outer .ant-list-item .company-li-div span {
    font-size: 20px;
    margin-right: 10px;
  }
  .companylist-model .company-dropdown-outer .ant-list-item .company-li-div a.company-select-icon {
    font-size: 13px;
  }
  .companylist-model .company-dropdown-outer .notifications_body_div .notifications_type_div {
    height: 32px;
  }
  .companylist-model .company-dropdown-outer .notifications_body_div .notifications_type_div .activetype {
    border-bottom: 2px solid #65d3c2;
    margin-bottom: -5px;
    padding-bottom: 4px;
  }
  .companylist-model .company-dropdown-outer .notifications_body_div .notifications_list .notifications_list_inner li.noty_li {
    padding: 5px 10px;
    height: 54px;
  }
  .companylist-model .company-dropdown-outer .notifications_body_div .notifications_list .notifications_list_inner li.noty_li span.noty_time {
    font-size: 12px;
  }
  .companylist-model .company-dropdown-outer .notifications_body_div .notifications_list .notifications_list_inner li.noty_li p.noty_text {
    font-size: 12px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) and (max-width: 1920px) {
  .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li .bnk-hd-li-link {
    font-size: 13px;
  }
  .single-icon-small > small {
    font-size: 14px !important;
  }
  #sidebar-menu ul li .itemp-a .sidebar_icon_title_span {
    margin-left: 12px;
    font-size: 16px;
  }
}
.notifications_body_div .notifications_type_div {
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px 0 1px 0 solid gray;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}
.notifications_body_div .notifications_type_div .notifications_type_btn {
  padding: 0 15px;
  color: #0bb197;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}
.notifications_body_div .notifications_type_div .activetype {
  border-bottom: 3px solid #65d3c2;
  margin-bottom: -11px;
  padding-bottom: 8px;
}
.notifications_body_div .notifications_list .notifications_list_inner li.noty_li {
  /* background-color: #0bb19747; */
  padding: 10px 10px;
  /* border-bottom: 1px solid gray; */
  height: 70px;
  overflow: auto;
  background: linear-gradient(to bottom, rgba(11, 177, 151, 0.07), rgba(11, 177, 151, 0.23));
}
.notifications_body_div .notifications_list .notifications_list_inner li.noty_li span.noty_time {
  color: #0bb197;
}
.notifications_body_div .notifications_list .notifications_list_inner li.noty_li p.noty_text {
  color: black;
  overflow: hidden;
  height: 22px;
  width: 95%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding: 0 0 0 10px;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  /* border-right-width: 1px; */
  padding: 0px 8px;
}

.custom-header-center .ag-header-cell-label .ag-header-cell-text {
  display: flex;
  width: 100%;
  justify-content: center;
  /* text-align: center; */
  margin-right: 15px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper table {
  height: 44px;
}

.addRole .back_roll_btn :where(.css-dev-only-do-not-override-z1i11c).ant-btn-default.ant-btn {
  background-color: #0bb197;
  color: white;
  font-weight: 600;
  width: 35px;
  overflow: hidden;
  height: 35px;
}
.addRole .addRoleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
  height: 100px;
  background-color: white;
  padding: 10px 15px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
}
.addRole .addRoleHeader input#roleName {
  font-size: 14px;
  padding: 5px 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #d3d2ca;
}
.addRole .addRoleHeader label.accessLable {
  margin: 36px 10px 0 0;
  font-size: 16px;
  font-weight: 400;
}
.addRole .addRoleBody {
  display: block;
  height: 67vh;
  border: 1px solid #d6d6d6;
  margin: 15px;
  border-radius: 10px;
}
.addRole .addRoleFooter .addRole-btn {
  border: 1px solid;
  border-radius: 4px;
  box-shadow: none;
  background: none;
  font-size: 12px;
  height: 26px;
  width: 80px;
  margin: 10px;
}

.invoice-outer {
  display: block;
}
.invoice-outer .invoice-inner {
  display: block;
}
.invoice-outer .invoice-inner .invoice-heading-outer {
  border-bottom: 2px solid #eff2f7;
  height: 10%;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 13px;
  height: 40px;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .beck-btn {
  display: flex;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .beck-btn Button {
  width: 25px;
  height: 25px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 5px 0 9px;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .beck-btn Button span.anticon.anticon-arrow-left {
  margin-left: -5px;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .beck-btn .invoice_heading_text svg {
  width: 20px;
  height: 20px;
  margin: 2px 0 0 6px;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .Steps-div {
  width: 550px;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .invoice-btn {
  display: flex;
  align-items: center;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .invoice-btn .invoice-common {
  margin: 0 0 0 10px;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .invoice-btn .Configration-btn {
  margin: 0 0 0 10px;
  height: 26px;
  padding: 0px 12px;
}
.invoice-outer .invoice-inner .invoice-heading-outer .invoice-heading .invoice-btn .Configration-btn span {
  font-size: 14px;
}
.invoice-outer .invoice-inner .invoice_body_inner {
  display: flex;
  margin: 10px 10px 0;
}
.invoice-outer .invoice-inner .invoice_body_inner .manual_ivc_card {
  border: 1px solid #c8c8c8;
  height: calc(100vh - 100px);
  margin: 3px;
  border-radius: 5px;
  overflow-x: scroll;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher {
  width: 65%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card {
  display: block;
  border-bottom: 1px dashed #c8c8c8;
  padding: 5px 15px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div {
  display: flex;
  justify-content: left;
  margin: 15px 0;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div .voucher_type {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div .voucher_type label {
  display: inline-block;
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 400;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div .voucher_type .voucher_type_select {
  width: 450px;
  height: 28px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div .voucher_type .voucher_no_select {
  width: 250px;
  height: 28px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div .voucher_type .party_name_div {
  display: flex;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div .voucher_type .party_name_div .party_name_select {
  margin-right: 15px;
  width: 390px;
  height: 28px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .input_div .voucher_type .party_name_div .party_name_btn {
  height: 28px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card .left_card_titel {
  font-weight: 600;
  margin-bottom: 10px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border {
  width: 100%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr {
  height: 40px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .comman_class_table_th {
  border: 1px solid #d2d0d0;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  padding: 0 10px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .comman_class_table_th .add_row_btn {
  width: 20px;
  height: 20px;
  color: white;
  background-color: #0bb197;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw-5 {
  width: 5%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw-35 {
  width: 25%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw-10 {
  width: 20%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw-amount {
  width: 20%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_action-column {
  width: 15%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw_65 {
  width: 65%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw_35 {
  width: 50%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw_30 {
  width: 25%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .common_tr .s_tw_15 {
  width: 10%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .table_heading_tr {
  height: 40px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .table_heading_tr .comman_class_table_th {
  background-color: rgb(242, 242, 242);
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .table_body_tr {
  height: 50px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .table_body_tr .voucher_type_select {
  width: 100%;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .table_total_tr {
  height: 35px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .table_total_tr .comman_class_table_th {
  text-align: left;
  background-color: rgb(242, 242, 242);
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_first_card table.s_transaction-content-box-table-border .table_total_tr .comman_class_table_th .table_total {
  display: flex;
  justify-content: space-evenly;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card {
  display: flex;
  padding: 5px 15px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card .narration_left_div {
  width: 60%;
  padding: 0 15px 0 0;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card .narration_left_div .left_card_titel {
  font-weight: 600;
  margin-bottom: 10px;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card .narration_left_div textarea {
  overflow: auto;
  resize: vertical;
  width: -webkit-fill-available;
  height: 150px;
  outline: none;
  border-color: #c8c8c8;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card .narration_right_div {
  width: 40%;
  background-color: rgb(242, 242, 242);
  margin: 10px 0;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card .narration_right_div .narration_Total_div {
  display: flex;
  justify-content: space-between;
  margin: 25px 15px;
  align-items: center;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card .narration_right_div .narration_Total {
  height: 100px;
  border-bottom: 1px dashed #c8c8c8;
}
.invoice-outer .invoice-inner .invoice_body_inner .left_side_voucher .left_last_card .narration_right_div .total_bgm {
  font-weight: 700;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer {
  width: 35%;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_first_card {
  display: block;
  border-bottom: 1px dashed #c8c8c8;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_first_card .first_card_heding {
  border-bottom: 1px solid #c8c8c8;
  padding: 5px 10px;
  background-color: #f0f0f0;
  font-weight: 600;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_first_card .details {
  display: flex;
  padding: 5px 10px;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_first_card .details span {
  width: 125px;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_first_card textarea {
  overflow: auto;
  resize: vertical;
  margin: 10px;
  border-color: #c8c8c8;
  width: -webkit-fill-available;
  height: 150px;
  outline: none;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_second_card {
  display: flex;
  padding: 10px 5px;
  border-bottom: 1px solid #c8c8c8;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_second_card .right_second_card_div {
  border: 1px solid;
  margin: 0 5px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_second_card .right_second_card_lefy {
  border-color: #aaffd2;
  background: #eaf7f0;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_second_card .right_second_card_right {
  background: #f9f5f5;
  border-color: #d5d2d2;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_third_card {
  display: block;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_third_card .right_third_heading {
  border-bottom: 1px solid #c8c8c8;
  padding: 5px 10px;
  background-color: #f0f0f0;
  font-weight: 600;
}
.invoice-outer .invoice-inner .invoice_body_inner .right_side_customer .right_third_card .right_third_space {
  padding: 5px 10px;
}
.invoice-outer .invoice-inner .invoice_footer_outer .invoice-footer {
  display: flex;
  justify-content: end;
  margin: 0 10px;
}
.invoice-outer .invoice-inner .invoice_footer_outer .invoice-footer .invoice-btn {
  margin: 5px 5px;
}

